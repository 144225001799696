<template>
  <v-card light>
    <v-container fluid fill-height class="BackgroundStyle-2">
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4 lg3>
          <div class="Logo1" width="100%">
            <img src="../../assets/Mww128.png" alt="My Wallet Watcher" align-center>
          </div>
          <v-card class="elevation-3">
            <v-card-text>
              <v-card-title class="primary-title text-xs-center">
                <div>
                  <h3 class="headline"> Set new password </h3>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-form @submit="valCheck($v.passwords)">
                <v-alert type="error" dismissible :value="true" v-model="showErrorMessage">
                  {{errorMessage}}
                </v-alert>
                <v-text-field v-model="passwords.opassword"
                              label="Old Password"
                              :append-icon="opasswordShow ? 'visibility_off' : 'visibility'"
                              :type="opasswordShow ? 'text' : 'password'"
                              :error-messages="oPasswordErrors"
                              @input="$v.passwords.opassword.$touch()"
                              @blur="$v.passwords.opassword.$touch()"
                              @click:append="opasswordShow = !opasswordShow"></v-text-field>
                <v-text-field v-model="passwords.password"
                              label="Password"
                              :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
                              :type="passwordShow ? 'text' : 'password'"
                              :rules="[rules.strength]"
                              :error-messages="passwordErrors"
                              @input="$v.passwords.password.$touch()"
                              @blur="$v.passwords.password.$touch()"
                              @click:append="passwordShow = !passwordShow"></v-text-field>
                <v-text-field v-model="passwords.cpassword"
                              label="Confirm Password"
                              :append-icon="cpasswordShow ? 'visibility_off' : 'visibility'"
                              :type="cpasswordShow ? 'text' : 'password'"
                              :error-messages="cPasswordErrors"
                              @input="$v.passwords.cpassword.$touch()"
                              @blur="$v.passwords.cpassword.$touch()"
                              @click:append="cpasswordShow = !cpasswordShow"></v-text-field>
                <v-btn type="submit" color="primary" :loading="loading" rounded block v-on:click.prevent="valCheck($v.passwords)">
                  Change Password
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    
  </v-card>
</template>


<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs } from 'vuelidate/lib/validators'

  import { passwordFunctions } from '../../javascript/passwordChecker'
  import { HTTP } from '../../api/httpCommon';

  export default {
    mixins: [validationMixin],
    data(vm) {
      return {
        opasswordShow: false,
        passwordShow: false,
        cpasswordShow: false,
        loading: false,
        showErrorMessage: false,
        errorMessage: '',
        passwords: {
          opassword: '',
          password: '',
          cpassword: ''
        },
        rules: {
          strength: vm.getPasswordStrength
        },
      }
    },
    computed:{
      oPasswordErrors() {
        const errors = []
        if (!this.$v.passwords.opassword.$dirty) return errors
        !this.$v.passwords.opassword.required && errors.push('Old Password is required')
        return errors
      },
      passwordErrors() {
        const errors = []
        if (!this.$v.passwords.password.$dirty) return errors
        !this.$v.passwords.password.required && errors.push('Password is required')
        return errors
      },
      cPasswordErrors() {
        const errors = []
        if (!this.$v.passwords.cpassword.$dirty) return errors
        !this.$v.passwords.cpassword.sameAsPassword && errors.push(' Passwords must be identical')
        !this.$v.passwords.cpassword.required && errors.push('Password is required')
        return errors
      },
    },
    methods: {
      valCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if ($v.opassword.$invalid || $v.password.$invalid || $v.cpassword.$invalid) {
          return
        }

        this.loading = true
        this.changePassword()
      },
      getPasswordStrength: function () {
        return passwordFunctions.isPasswordStrength(this.passwords.password)
      },
      changePassword: function () {
        this.showDismissibleAlert = true,
          
          HTTP.post('ChangePassword',
            this.passwords
          )
            .then(response => {
              if (response.status === 200) {
                this.$router.push({ path: '/dashboard/passwordChanged/Success' })
              }
              else if (response.status === 401) {
                this.errorMessage = "Incorrect Old Password"
                this.showErrorMessage = true
              }
              //else if (response.status === 404) {
              //  this.errorMessage = "Are you Registered? Please Resgister"
              //  this.showErrorMessage = true
              //}
              else {
                this.errorMessage = "Something Went Wrong Please try again"
                this.showErrorMessage = true
              }
              this.loading = false
            })
            .catch(e => {

              if (e.response.status === 401) {
                this.errorMessage = "Incorrect Old Password"
                this.showErrorMessage = true
              }
              //else if (e.response.status === 404) {
              //  this.errorMessage = "Are you Registered? Please Resgister"
              //  this.showErrorMessage = true
              //}
              else {
                this.errorMessage = "Something Went Wrong Please try again"
                this.showErrorMessage = true
              }

              console.log(e)
              this.loading = false
            })

      }
    },
    validations: {
      passwords: {
        opassword: {
          required
        },
        password: {
          required
        },
        cpassword: {
          required,
          sameAsPassword: sameAs('password')
        },
      }
    }
  }
</script>
